import React from 'react';

const Inputs = ({ inputs, onChangeInput }) => {
  const setInputValue = (input, e) => {
    onChangeInput(input, e.target.value);
  }
  return <div className="inputs">
    <form>
    <table cellSpacing="0">
      <tbody>
      <tr className="distance-per-unit">
        <th className="label">Brandstofverbruik {inputs.per}</th>
        <td><div className="wrapper">
          <input type="number" min="0" max="999.99" pattern="[0-9]*" inputMode="decimal" step="0.01" onChange={evt => setInputValue('distancePerUnit', evt)} value={inputs.distancePerUnit} />
        </div></td>
      </tr>
      <tr className="fuel-price">
        <th className="label">Brandstofprijs ({inputs.unit})</th>
        <td><div className="wrapper">
          &euro;<input lang="nl-NL" type="number" min="0" max="9.99" pattern="[0-9]*" inputMode="decimal"  step="0.01" onChange={evt => setInputValue('fuelPrice', evt)} value={inputs.fuelPrice} />
        </div></td>
      </tr>
      </tbody>
    </table>
    </form>
  </div>
}

export default Inputs;