import Constants from './Constants';
import Calculator from './services/Calculator';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import cookie from 'react-cookies';
//import logo from './logo.svg';
//import InputForm from './InputForm.js';
import FuelList from './FuelList';
import Inputs from './Inputs';
import Results from './Results';

import './App.scss';
import './slide-configurator.scss';
import './slide-results.scss';


class App extends Component {

  constructor(props) {
    super(props);
    this.introMarginTop = 0;
    this.pageNames = [
      'start',
      'results'
    ];

    this.state = {
      showIntro: 1,
      activePage: 1,
      fuelSelected: 0,
      inputs: {
        fuelPrice: 0,
        distancePerUnit: cookie.load('distancePerUnit') || 18,
        distancePerYear: cookie.load('distancePerYear') || 15000,
        unit: 'L',
      },
      costDifference: []
    };
  }

  componentDidMount() {
    var selection = cookie.load('selection');
    const fuelSelection = Constants['fuelList'][0];
    if (!selection) {
      this.onSelectFuel(Constants['fuelList'][0]);
    } else {
      var price = fuelSelection.price;
      var unit = fuelSelection.unit;
      var per = fuelSelection.per;
      var distance = fuelSelection.distance;
      var userFuelList = cookie.load('fuelList');
      if (!userFuelList) userFuelList = Constants['fuelList'];
      var userFuelSelection = userFuelList[selection];
      if (userFuelSelection) {
        price = userFuelSelection.price;
        distance = userFuelSelection.distance;
        per = userFuelSelection.per;
      }

      var inputs = this.state.inputs;
      inputs['fuelPrice'] = price;
      inputs['distancePerUnit'] = distance;
      inputs['unit'] = unit;
      inputs['per'] = per;
      this.setState({
        fuelSelected: parseInt(selection),
        inputs: inputs
      });
    }
  }

  start = () => {
    let wrapper = document.getElementById("wrapper");
    this.introMarginTop = wrapper.offsetTop;
    this.setState({ showIntro: 0 }, () => {
      this.animateMarginTop();
      //this.animateHeight(this.introMarginTop, document.getElementById("wrapper").clientHeight);
    });
  }


  getSavings = (labelA, labelB) => {
    var calculator = new Calculator(this.state.inputs.fuelPrice, this.state.inputs.distancePerUnit);
    return {
      savings: calculator.getCostDifferencePer('lifespan',labelA, labelB),
      rDelta: calculator.getRDelta(labelA, labelB),
      costLabelA: calculator.getCostForLabelPer('lifespan',labelA),
      costLabelB: calculator.getCostForLabelPer('lifespan',labelB)
    }
    //this.setState({ costDifference: calculator.getCostDifferencePer('lifespan',this.state.bar1, this.state.bar2)});
  }

  animateMarginTop = () => {
    let main = document.querySelector('.main');
    let wrapper = document.getElementById("wrapper");
    let slideWrapper = wrapper.querySelector('.slideWrapper');
    let intro = slideWrapper.querySelector('#intro');
    let configurator = slideWrapper.querySelector('#configurator');

    wrapper.style.marginTop = this.introMarginTop + 'px';
    slideWrapper.style.height = slideWrapper.clientHeight + 'px';

    setTimeout(() => {
      wrapper.style.marginTop = (0 - document.querySelector('#logo-kdbb').clientHeight) + 'px';
      intro.style.opacity = 0;
      configurator.style.position = 'relative';
      configurator.style.opacity = 1;

      setTimeout(() => {
        intro.style.display = 'none';

        console.log((main.clientHeight - (slideWrapper.getBoundingClientRect().top - main.getBoundingClientRect().top)));
        slideWrapper.style.height = 'calc(' + (main.clientHeight - (slideWrapper.getBoundingClientRect().top - main.getBoundingClientRect().top)) + 'px - 1.25rem)';


        //slideWrapper.style.height = 'auto';
        slideWrapper.classList.add('introHidden');
      }, 600);
    },100);


  }

  animateHeight = (el, oldHeight, newHeight) => {
    if (oldHeight === newHeight) {
      return;
    }
    //this.animatedHeight = newHeight;
    el.style.height = oldHeight + 'px';
    setTimeout(() => {
      el.style.height = newHeight + 'px';
    },100);
  }

  showResults = () => {
    ReactDOM.render(
      // Try changing to isLoggedIn={true}:
      <Results distancePerYear={this.state.inputs.distancePerYear} getSavings={this.getSavings} goToSlide={this.goToSlide} />,
      document.getElementById('results')
    );

    this.goToSlide(2);
  }

  goToSlide = (slide) => {
    this.setState({ activePage: slide });
  }

  onChangeInput = (input, value) => {
    var inputs = this.state.inputs;
    inputs[input] = value;
    this.setState({
      inputs: inputs
    });
    if (input === 'fuelPrice' || input === 'distancePerUnit') {
      const userFuelList = cookie.load('fuelList');
      if (userFuelList) {
        const selected = this.state.fuelSelected;
        const selectedFuel = userFuelList[selected];
        switch(input) {
          case 'fuelPrice':
            selectedFuel.price = value;
          break;
          case 'distancePerUnit':
            selectedFuel.distance = value;
          break;
          default:
          break;
        }
        selectedFuel.per = selected.per;
        userFuelList[selected] = selectedFuel;
        cookie.save('fuelList', userFuelList);
      }
    } else {
      cookie.save(input, value)
    }
  }

  onSelectFuel = (item) => {
    cookie.save('selection', item.id);
    const userFuelList = cookie.load('fuelList');
    var price = item.price;
    var distance = item.distance;
    var unit = item.unit;
    var per = item.per;

    if (userFuelList) {
      const selectedFuel = userFuelList[item.id];
      if (selectedFuel) {
        price = selectedFuel.price;
        distance = selectedFuel.distance;
        per = selectedFuel.per;
      } else {
        userFuelList[item.id] = Constants.fuelList[item.id];
        cookie.save('fuelList', userFuelList);
      }
    } else {
      cookie.save('fuelList', Constants.fuelList);
    }

    var inputs = this.state.inputs;
    inputs.fuelPrice = price;
    inputs.distancePerUnit = distance;
    inputs.unit = unit;
    inputs.per = per;
    this.setState({
      fuelSelected: item.id,
      inputs: inputs
    });

  };

  getActivePageName = () => {
    return this.pageNames[this.state.activePage-1];
  }

  render() {
    var backButton;
    if (this.state.activePage > 1) {
      backButton = <div className="back-button" onClick={() => this.goToSlide(1)}></div>;
    } else {
      backButton = null;
    }

    return (
      <div data-show-intro={this.state.showIntro} data-active={this.state.activePage} className="main" id={"page-" + this.getActivePageName()}>

        {backButton}

        <div id="wrapper">
          <div id="logo-kdbb">
            <img src="/images/Logo/Kies de beste band.svg" alt="Logo 'Kies de beste band'" />
          </div>
          <header>
            <img className="logo" alt="Bandencalculator" src="/images/bandencalculator.svg" />
            <div className="payoff">Bereken hoeveel je kan besparen door een<br/> beter bandenlabel voor brandstofefficiëntie te kiezen.</div>
          </header>
          <div className="content">
            <div className="slideWrapper">
              <div className="slide">
                <div id="intro">
                  <button className="chunky" onClick={this.start}>Check je besparing</button>
                </div>
                <div id="configurator" style={{position: 'absolute', opacity: 0}}>
                  <p>Selecteer je brandstof</p>
                  <FuelList selected={this.state.fuelSelected} onSelectFuel={this.onSelectFuel} fuelList={Constants.fuelList} />
                  <Inputs inputs={this.state.inputs} onChangeInput={this.onChangeInput} />
                  <button onClick={this.showResults}>Bekijk de labels</button>
                </div>
              </div>
              <div className="slide" id="results"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;